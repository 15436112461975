<template>
	<div class="nav">
		<!-- <el-tabs v-model="activeName" @tab-click="handleClick" :style="{width: width + 'px'}">
			<el-tab-pane v-for="(item,index) in tabsList" :key="index" :label="item.name" :name="item.url"></el-tab-pane>
		</el-tabs> -->
		<el-menu router :style="{width: width +'%'}" :default-active="$route.path" class="el-menu-demo"
			mode="horizontal" @select="handleSelect">
			<el-menu-item v-for="(item,index) in tabsList" :index="item.url" :key="index">
				{{item.name}}</el-menu-item>
		</el-menu>
		<div class="all-select" v-if="type == true">
			<el-dropdown @command="clickDropdown">
				<span class="el-dropdown-link">
					{{selectText}}<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item :command="commandValue(index)" v-for="(item,index) in dropdownList" :key="index">{{item.name}}</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>

</template>

<script>
	import {
		setTheme
	} from "@/assets/theme";
	export default {
		props: {
			width: {
				type: Number,
				default: 0
			},
			type: {
				type: Boolean,
				default: false,
			},
			dataObj: {
				type: Object,
				default () {
					return {}
				}
			},
			tabsList: {
				type: Array,
				default () {
					return []
				}
			},
			dropdownList: {
				type: Array,
				default () {
					return []
				}
			}
		},
		mounted() {
			console.log(this.tabsList, 'tabsList mounted')
			if(this.$store.state.purchaseType == 1){
				this.selectText = this.$t('havepaid')
			}else {
				this.selectText = this.$t('all')
			}
		},
		created() {
			console.log(this.tabsList, 'tabsList created')
		},
		data() {
			return {
				activeName: '/MusicianPersonalCenterFinished',
				selectText: '',
				status: 3,//支付状态 0未支付 1已支付 3全部
			};
		},
		methods: {
			handleSelect() {

			},
			handleClick(tab, event) {
				console.log(tab, event);
				this.activeName = tab.name
				this.$router.push({
					path: tab.name
				})
			},
			commandValue(type) {
				// console.log(type,'commandValue')
			  return {
			    type,
			  };
			},
			clickDropdown(command) {
			  console.log(command,'clickDropdown');
			  let { type } = command;
			  if (type == "0") {
				  this.selectText = this.dropdownList[0].name
				  this.status = 3
			    console.log("全部");
				
			  } else if (type == "1") {
				  this.selectText = this.dropdownList[1].name
				  this.status = 1
			    console.log("已支付");
			  } else {
				  this.selectText = this.dropdownList[2].name
				  this.status = 0
			    console.log("未支付");
			  }
			  this.$emit('select',this.status)
			},
		}
	};
</script>

<style>
	.el-tabs__item.is-active {
		color: #ffffff !important
	}

	.el-tabs__active-bar {
		background-color: #ffffff !important;
	}

	.el-tabs__nav-wrap::after {
		position: static !important;
	}

	/*.el-tabs__item {*/
	/*  padding: 0 2rem;*/
	/*  height: 2.5rem;*/
	/*  box-sizing: border-box;*/
	/*  display: inline-block;*/
	/*  list-style: none;*/
	/*  font-size: 0.875rem;*/
	/*  color: rgba(255, 255, 255, 0.7) !important;*/
	/*  position: relative;*/
	/*  font-size: 16px;*/
	/*  font-family: PingFangSC-Regular, PingFang SC;*/
	/*  font-weight: 400 !important;*/
	/*  line-height: 25px;*/
	/*  margin-top: 27px;*/

	/*}*/
</style>
<style lang="less" scoped>
	// ::v-deep .nav ul{
	// 	width: 100%;
	// }
	.nav {
		width: 100%;
		height: 55px;
		background: @recommendMusicianListBGColor;
		border-radius: 6px;
		/* filter: blur(50px); */
		/* backdrop-filter: blur(50px); */
		display: flex;
		justify-content: space-between;
		align-items: center;

		::v-deep .el-menu {
			background: rgba(255, 255, 255, 0);

			&.el-menu--horizontal {
				border-bottom: none;
			}
		}

		::v-deep .el-menu-item {
			border-bottom: none;
			text-decoration: none;
			list-style: none;
			font-size: 16px;
			font-weight: 400 !important;
			color: #999999 !important;

			&.is-active {
				background: rgba(255, 255, 255, 0);
				color: @headerSelectTextColor  !important;
				font-size: 16px;
				font-weight: 600 !important;
			}

			&:hover {
				background: rgba(255, 255, 255, 0);
				color: @headerSelectTextColor  !important;
				font-size: 16px;
				font-weight: 600 !important;
			}
		}


		.all-select {
			width: 100px;
			display: flex;
			justify-content: flex-end;
			padding-right: 20PX;
		}
	}
</style>
