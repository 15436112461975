<template>
	<div class="main">
		<div class="nav-lf">
		  <MusicType
		    :width="952"
		    :type="type"
		    :tabsList="tabsList"
		    :dropdownList="dropdownList"
		  ></MusicType>
		</div>
		<div>
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import MusicType from "@/components/content/MusicType.vue";
	export default {
		props: {},
		computed: {},
		components: {
			MusicType
		},
		watch: {
			"$store.state.groupType": {
				handler(newValue, oldValue) {
					console.log("groupType变化:", newValue);
					if(this.$store.state.identity == 2 &&  newValue == '1'){
						let arr = [{
							name: this.$t('personaldetails'),
							url: '/personalCenter/accountManagement/personInformation'
						},
						{
							name: this.$t('realname'),
							url: '/personalCenter/accountManagement/realnameAuthentication'
						},
						{
							name: this.$t('accountsecurity'),
							url: '/personalCenter/accountManagement/accountSecurity'
						},
						]
						this.tabsListMenu = arr
					}else {
						let arr = [{
							name: this.$t('personaldetails'),
							url: '/personalCenter/accountManagement/personInformation'
						},
						{
							name: this.$t('realname'),
							url: '/personalCenter/accountManagement/realnameAuthentication'
						},
						{
							name: this.$t('enterprise'),
							url: '/personalCenter/accountManagement/enterpriseCertification'
						},
						{
							name: this.$t('accountsecurity'),
							url: '/personalCenter/accountManagement/accountSecurity'
						},
						]
						this.tabsListMenu = arr
					}
					
				},
			},
		},
		created() {
			console.log(this.$store.state.identity,'2333222');
			if(this.$store.state.identity == 2 && this.$store.state.groupType == 1){
				let arr = [{
					name: this.$t('personaldetails'),
					url: '/personalCenter/accountManagement/personInformation'
				},
				{
					name: this.$t('realname'),
					url: '/personalCenter/accountManagement/realnameAuthentication'
				},
				{
					name: this.$t('accountsecurity'),
					url: '/personalCenter/accountManagement/accountSecurity'
				},
				]
				this.tabsList = arr
			}else {
				let arr = [{
					name: this.$t('personaldetails'),
					url: '/personalCenter/accountManagement/personInformation'
				},
				{
					name: this.$t('realname'),
					url: '/personalCenter/accountManagement/realnameAuthentication'
				},
				{
					name: this.$t('enterprise'),
					url: '/personalCenter/accountManagement/enterpriseCertification'
				},
				{
					name: this.$t('accountsecurity'),
					url: '/personalCenter/accountManagement/accountSecurity'
				},
				]
				this.tabsList = arr
			}
		},
		data(){
			return {
				type: false,
				tabsList: [],
				dropdownList: []
			}
		},
		methods: {
			
		}
	}
</script>

<style lang="less" scoped>
	.main{
		// color: palevioletred;
		margin-left: 28px;
		::v-deep .nav .el-menu-item.is-active {
			border-bottom: 2px solid @recommendMusicianListTextColor
			// position: relative;
		}
		// ::v-deep .nav .el-menu-item.is-active::after{
		// 	position: absolute;
		// 	content: '';
		// 	background-color: #ffffff;
		// 	right: 0;
		// 	top: 50%;
		// 	transform: translate(0, -50%);
		// 	height: 30rpx;
		// 	width: 15rpx;
		// 	border-radius: 15px 0 0 15px;
		// }
	}
</style>